<template >
    <div class="row" v-if="data.prix_bresil"> 
        <div class="col-12 col-lg-12 wrapper-box-fiche"> 
            <div class="box-white-fiche">

                    <!--Data Block1 start-->
                    <table class="comparateur2 text-center">    
                        <tbody>
                            <thead>
                                <tr>
                                    <th class="gray" v-if="data.prix_bresil.ncpj">CNPJ</th>
                                    <th class="gray" v-if="data.prix_bresil.ggrem">CÓDIGO GGREM</th>
                                    <th class="gray" v-if="data.prix_bresil.ean">EAN</th>
                                    <th class="gray" v-if="data.prix_bresil.drug_number">Drug number</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td v-if="data.prix_bresil.ncpj">{{data.prix_bresil.ncpj}}</td>
                                    <td v-if="data.prix_bresil.ggrem">{{data.prix_bresil.ggrem}}</td>
                                    <td v-if="data.prix_bresil.ean">{{data.prix_bresil.ean}}</td>
                                    <td v-if="data.prix_bresil.drug_number">{{data.prix_bresil.drug_number}}</td>
                                </tr>
                            </tbody>
                        </tbody>
                    </table>
                <!--Data Block1 end-->


                    <table class="comparateur2 text-center" v-if="data.prix_bresil.classification || data.prix_bresil.use_only || data.prix_bresil.cap || data.prix_bresil.confaz || data.prix_bresil.grant_of_tax || data.prix_bresil.comercialization">
                        <tbody>
                                <tr v-if="data.prix_bresil.classification">
                                    <td class="gray">Classification</td>
                                    <td>{{data.prix_bresil.classification}}</td>
                                </tr>
                                <tr v-if="data.prix_bresil.use_only">
                                    <td class="gray">Hospital use only</td>
                                    <td>{{data.prix_bresil.use_only}}</td>
                                </tr>
                                <tr v-if="data.prix_bresil.cap">
                                    <td class="gray">CAP</td>
                                    <td>{{data.prix_bresil.cap}}</td>
                                </tr>
                                <tr v-if="data.prix_bresil.confaz">
                                    <td class="gray">CONFAZ 87</td>
                                    <td>{{data.prix_bresil.confaz}}</td>
                                </tr>
                                <tr v-if="data.prix_bresil.grant_of_tax">
                                    <td class="gray">List of grant of Tax credit (PIS / COFINS)</td>
                                    <td>{{data.prix_bresil.grant_of_tax}}</td>
                                </tr>
                                <tr v-if="data.prix_bresil.comercialization">
                                    <td class="gray">Comercialization 2016</td>
                                    <td>{{data.prix_bresil.comercialization}}</td>
                                </tr>
                        </tbody>
                    </table>

            <!--PrixBresilGovernment start-->
                <table class="comparateur2 text-center" v-if="data.prix_bresil.prix_bresil_government">
                    <tr>
                        <td width="33%" style="border-top: 1px solid #FFFFFF; border-left: 1px solid #FFFFFF;"></td>
                        <td width="33%" >Factory price ex-VAT</td>
                        <td width="33%" >Maximum Sale Price to Government</td>
                    </tr>
                    <tr style="text-align: center;">
                        <th class="gray">Date</th>
                        <th class="gray">PF (ICMS 0%)<br>(Real)</th>
                        <th class="gray">PMVG (ICMS 0%)<br>(Real)</th>
                    </tr>
                        <tr v-for="(value_government, key) in data.prix_bresil.prix_bresil_government" :key="'government_'+key">
                            <td>{{value_government.date}}</td>
                            <td>{{value_government.pf_0}}</td>
                            <td>{{value_government.pmvg_0}}</td>
                        </tr>
                </table>
            <!--PrixBresilGovernment end-->

            <!--PrixBresilConsumer start-->
                <table class="comparateur2 text-center" v-if="data.prix_bresil.prix_bresil_consumer">
                    <tr>
                        <td width="33%" style="border-top: 1px solid #FFFFFF; border-left: 1px solid #FFFFFF;"></td>
                        <td width="33%" >Factory price ex-VAT</td>
                        <td width="33%" >Maximum Consumer Price</td>
                    </tr>
                    <tr style="text-align: center;">
                        <th class="gray">Date</th>
                        <th class="gray">PF (ICMS 0%)<br>(Real)</th>
                        <th class="gray">PMC (ICMS 0%)<br>(Real)</th>
                    </tr>
                        <tr v-for="(value_consumer, key) in data.prix_bresil.prix_bresil_consumer" :key="'consumer_'+key">
                            <td>{{value_consumer.date}}</td>
                            <td>{{value_consumer.pf_0}}</td>
                            <td>{{value_consumer.pmc_0}}</td>
                        </tr>
                </table>
            <!--PrixBresilConsumer end-->
            </div>
        </div>
    </div>   
</template>


<script>
export default {
  name: "prix_bresil",
  components: {},
  methods: {},
  mounted() {
    if (this.$i18n.locale == "de") {
      this.lang = "de";
    }
  },
  updated() {},
  computed: {
      data() {
            return this.$store.getters['detail/data']
        }
  },
  unmounted() {},
};
</script>